import { AuthApiError, User } from "@supabase/supabase-js";
import { supabase } from "../config/supabase";
import { IUser } from "../context/UserContext";
import { IClassicLeagues } from "./types/types";
import { redColor, secondaryColor } from "../config/theme";
import { SESSION_STORAGE } from "../config/app.config";

/** return and supabase user */
export const getUserSupabase = async () => {
  const {
    data: { user },
    error: getUserError,
  } = await supabase.auth.getUser();
  if (getUserError) throw getUserError;

  return user;
};

export const getFormattedUser = async () => {
  const user = await getUserSupabase();
  return formatUser(user);
};

/** Formate supabase user object to match the type of the context users */
export const formatUser = (user: User | null) => {
  if (
    user &&
    user.email &&
    user.identities &&
    user.identities[0].identity_data
  ) {
    return {
      id: user.id,
      email: user.email,
      teamId: user.user_metadata.teamId,
      email_verified: user.identities[0].identity_data.email_verified,
      selectedLeagueId: user.user_metadata.selectedLeagueId,
      favoriteLeagueId: user.user_metadata.favoriteLeagueId,
      isAnonymous: user.is_anonymous,
    } as IUser;
  } else if (user && user.is_anonymous) {
    return {
      id: user.id,
      isAnonymous: user.is_anonymous,
      teamId: Number(sessionStorage.getItem(SESSION_STORAGE.ANON_USER_TEAM_ID)),
    } as IUser;
  }
  return undefined;
};

/** Set the selected league in the user metadata and return a formatted user object */
export const setSelectedLeagueInUserMetaData = async (
  selectedLeagueId: string
) => {
  const user = await getUserSupabase();
  const {
    data: { user: newUser },
    error,
  } = await supabase.auth.updateUser({
    data: { ...user?.user_metadata, selectedLeagueId },
  });
  if (error) throw error;
  if (!user) throw new AuthApiError("", 401, "Unauthorized");
  return formatUser(newUser as User);
};

/** Set the Fav league in the user metadata and return a formatted user object */
export const setFavoriteLeagueInUserMetaData = async (
  favoriteLeagueId: string
) => {
  const user = await getUserSupabase();
  const {
    data: { user: newUser },
    error,
  } = await supabase.auth.updateUser({
    data: { ...user?.user_metadata, favoriteLeagueId },
  });
  if (error) throw error;
  if (!user) throw new AuthApiError("", 401, "Unauthorized");
  return formatUser(newUser as User);
};

/** Check if user has a teamId in the supabase metadata */
export const supabaseUserHasTeamId = async () => {
  const user = await getUserSupabase();
  return user?.user_metadata.teamId;
};

/** return league object  */
export const getLeagueById = (
  leagues: IClassicLeagues[],
  id: string | number
) => {
  return leagues.find((l) => l.id === Number(id));
};

/** Formats a large number into a string with appropriate suffixes (e.g., "k" for thousands, "M" for millions). */
export const formateNumbers = (num: number) => {
  switch (true) {
    case num > 10_000_000:
      return (num / 1_000_000).toFixed(1) + "M";
    case num > 1_000_000:
      return (num / 1_000_000).toFixed(2) + "M";
    case num > 100_000:
      return (num / 1_000).toFixed(0) + "k";
    case num > 10_000:
      return (num / 1_000).toFixed(1) + "k";
    case num > 1_000:
      return (num / 1_000).toFixed(2) + "k";
    default:
      return num;
  }
};

export const getPointsColor = (p1: number, p2: number = 0) => {
  if (p1 > p2) {
    return secondaryColor;
  } else if (p1 < p2) {
    return redColor;
  }
  return "#8E8E8E";
};

/**
 * Returns a color based on the difficulty value.
 * The difficulty value must be between 0 and 1.
 *
 * - 0: Red
 * - 0.25: Dark Red
 * - 0.5: Orange
 * - 0.75: Yellow
 * - 1: Green
 *
 * @param {number} chance - A number representing the chance (between 0 and 1).
 */
export function getPlayingChanceColor(chance?: number) {
  let bgColor: string = "white";
  let textColor: string = "black";
  let borderColor: string = "white";
  let iconColor: string = "black";

  if (chance === undefined) return { bgColor, textColor };

  // Determine the background color based on chance
  if (chance < 0.25) {
    bgColor = "#f9d9d5";
    textColor = "#000";
    borderColor = "#E2422C";
  } else if (chance < 0.5) {
    bgColor = "#fcefd9";
    textColor = "#000";
    borderColor = "#F2AE43";
  } else if (chance < 0.75) {
    iconColor = "#fff";
    bgColor = "#fff";
    textColor = "#000";
    borderColor = "#FFDE25";
  }

  return { bgColor, textColor, borderColor, iconColor };
}
