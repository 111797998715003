import { css, keyframes } from "@emotion/react";
import { Box, Stack } from "@mui/material";
import { mq, primaryGradient } from "@/config/styles";
import star from "@/assets/landing-page/star.svg";

const featuresList = [
  "Fixtures 2.0",
  "Captain Ownership",
  "Highlights",
  "Transfer Analysis",
];

/**
 * This component renders a scrolling strip on the landing page featuring
 * a list of app features. Each feature is displayed with an accompanying
 * star icon. The list is repeated multiple times and marked with aria-hidden
 * for accessibility purposes, ensuring that the strip fills larger screens
 * without repeating content in screen readers.
 */
export const StripSection = () => {
  return (
    <Stack css={rootStyles}>
      <Box css={sliderContainer}>
        <Stack component={"ul"} css={featuresListStyles}>
          {featuresList.map((item) => (
            <li key={item}>
              {item}
              <img src={star} />
            </li>
          ))}

          {/** Repeat content to fill large screen with aria hidden */}
          {featuresList.map((item) => (
            <li key={item} aria-hidden>
              {item}
              <img src={star} />
            </li>
          ))}
          {featuresList.map((item) => (
            <li key={item} aria-hidden>
              {item}
              <img src={star} />
            </li>
          ))}
          {featuresList.map((item) => (
            <li key={item} aria-hidden>
              {item}
              <img src={star} />
            </li>
          ))}
          {featuresList.map((item) => (
            <li key={item} aria-hidden>
              {item}
              <img src={star} />
            </li>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};

const rootStyles = css`
  ${primaryGradient}
  --height: clamp(5rem, 20vw, 7rem);
  height: var(--height);
  overflow: hidden;
  justify-content: center;
`;

const sliderContainer = css`
  transform: rotateZ(5deg);
  ${mq["md"]} {
    transform: rotateZ(3deg);
  }
`;

const animation = keyframes`
    from {
        transform: translateX(0) ;
    } 

    to {
        transform:  translateX(calc(-50% - 0.25rem)) ; // half the gap of ul
    }
`;

const featuresListStyles = css`
  height: calc(var(--height) * 0.5);
  max-height: 3rem;
  gap: 0.5rem;
  flex-direction: row;
  width: max-content;
  background-color: white;

  animation: ${animation} 30s linear infinite;

  /* transform: rotateZ(90deg); */

  & li {
    width: fit-content;
    color: black;
    font-weight: 600;
    font-size: clamp(0.875rem, 5vw, 1.2rem);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;

    img {
      width: clamp(1rem, 5vw, 1.5rem);
    }

    ${mq["md"]} {
      animation: ${animation} 40s linear infinite;
    }
  }
`;
