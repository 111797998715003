import { Helmet } from "react-helmet";
import { useParams, useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import {
  HighlightsCard,
  HighlightsCardSkeleton,
  HighlightsImportantKeyCell,
} from "./components";
import { useEffect, useState } from "react";
import {
  IHighlightCardDetails,
  IMPORTANT_SUFFIX,
  ISingleHighlightCard,
} from "@/utils/types/types";
import { Box, Stack, Typography } from "@mui/material";
import {
  AppTable,
  AppTableSkeleton,
  AppTableTeamCell,
  PageHeader,
  SelectGameWeekDropDown,
  AppShareButton,
  Skeleton,
  Button,
} from "@/components";
import { mq } from "@/config/styles";
import { GridColDef, GridEventListener } from "@mui/x-data-grid";
import { columnNumber } from "@/utils/muiTableColumns";
import { useGetUrl } from "@/utils/hooks/useGetUrl";
import { useNavigateState } from "@/utils/hooks/useNavigateState";
import { useGetHighlightsTabs } from "@/utils/hooks/useGetHighlightsTabs";
import { PATH_PARAMS } from "@/config/routes";
import { useGlobalInfoContext } from "@/context/GlobalInfoContext";
import { useIsSharedURL } from "@/utils/hooks/useIsSharedURL";
import { useGetHighlights } from "@/utils/hooks/useGetHighlights";
import { tableHeaderBgColor } from "@/config/theme";
import { useGetSingleHighlight } from "@/utils/hooks/useGetSingleHighlight";

/** Renders the single highlights page */
export const SingleHighlightsPage = () => {
  const { isShared } = useIsSharedURL();
  const [searchParams] = useSearchParams();
  const { selectedLeague } = useGlobalInfoContext();
  const { categoryId, highlightId } = useParams();
  const { fullHighlightsData, isLoading } = useGetHighlights();
  const [tabsData] = useGetHighlightsTabs();
  const [highlightData, setHighlightData] = useState<ISingleHighlightCard>();
  const [tableColumn, setTableColumn] = useState<
    GridColDef<IHighlightCardDetails>[]
  >([]);
  const navigate = useNavigateState();
  const { getTeamViewPath, getManagerTransfersPath } = useGetUrl();
  const [isAllSeasons, setIsAllSeason] = useState(false);
  const { getSingleHighlight, isSingleLoading } = useGetSingleHighlight({
    highlightId: highlightId!,
    season: true,
  });

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    if (!isShared) {
      if (!highlightId) return;

      if (highlightId.includes("transfers")) {
        return navigate(getManagerTransfersPath(params.row.entry));
      }

      return navigate(getTeamViewPath(params.row.entry));
    }
  };

  const fetchLuckiestDetails = async (shouldFetch: boolean) => {
    if (!shouldFetch) return null;
    const { data } = await getSingleHighlight();
    return data;
  };

  useEffect(() => {
    if (fullHighlightsData && tabsData) {
      (async () => {
        const highlightsData = fullHighlightsData.find(
          (highlight) =>
            highlight.highlight_id === highlightId &&
            highlight.category_id === categoryId
        );

        const tabData = tabsData.find(
          (tab) => tab.category_id === highlightsData?.category_id
        );

        if (highlightsData && tabData) {
          const data = await fetchLuckiestDetails(isAllSeasons);
          setHighlightData({
            ...highlightsData,
            ...tabData,
            ...(data && data),
          });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullHighlightsData, tabsData, isAllSeasons]);

  useEffect(() => {
    if (highlightData) {
      const extraColumns: GridColDef<IHighlightCardDetails>[] =
        highlightData.important_properties.map((imp) => ({
          field: imp.key,
          headerName: `${imp.display_name} ${IMPORTANT_SUFFIX[imp.type]}`,
          flex: 1.5,
          align: "center",
          headerAlign: "center",
          renderCell: (value) => (
            <HighlightsImportantKeyCell
              title={value.row[imp.key].toLocaleString("en-US")}
              accentColor={
                imp.color === "primary" ? highlightData.accent_color! : ""
              }
            />
          ),
        }));

      const teamColumn: GridColDef<IHighlightCardDetails> = {
        field: "team",
        headerName: "Team",
        flex: 1.5,
        sortable: false,
        renderCell: (value) => (
          <AppTableTeamCell
            mangerName={value.row.entry_name}
            teamName={value.row.player_name}
            accentColor={highlightData.accent_color}
          />
        ),
      };

      setTableColumn([columnNumber, teamColumn, ...extraColumns]);
    }
  }, [highlightData]);

  return (
    <div>
      <Helmet>
        <title>{highlightData?.display_name}</title>
      </Helmet>

      <Stack gap={3}>
        {
          <>
            {highlightData && !isSingleLoading ? (
              <>
                <PageHeader title={highlightData.display_name} />
                <Typography>{highlightData?.description}</Typography>
              </>
            ) : (
              <>
                <Skeleton sx={{ fontSize: "1.5rem" }} />
                <Skeleton sx={{ fontSize: "0.85rem" }} />
                <Skeleton sx={{ fontSize: "0.85rem" }} width={"45%"} />
              </>
            )}
          </>
        }

        <Stack css={gwAndShareContainer}>
          <SelectGameWeekDropDown />
          <Stack css={allSeasonAndShareContainer}>
            <Button
              button={isAllSeasons ? "outline-gradient" : "solid"}
              color={tableHeaderBgColor}
              onClick={() => setIsAllSeason(!isAllSeasons)}
            >
              All season
            </Button>

            {highlightData &&
              highlightData.details &&
              selectedLeague &&
              !isShared && (
                <AppShareButton
                  message={`"${highlightData.details[0].player_name}" is the "${
                    highlightData.display_name
                  }" for GW ${searchParams.get(
                    PATH_PARAMS.SELECTED_GAME_WEEK
                  )} in the "${
                    selectedLeague.name
                  }", check out how the rest of managers got on through FPL CHAMP!`}
                />
              )}
          </Stack>
        </Stack>

        <>
          {highlightData &&
          !isLoading &&
          !isSingleLoading &&
          highlightData.details ? (
            <Box>
              <HighlightsCard
                details={highlightData.details}
                toTeamViewURL={getTeamViewPath}
                cardStyles={singleHighlightCardStyle}
                accentColor={highlightData.accent_color}
                chipImageURL={highlightData.category_icon}
                importantProperties={highlightData.important_properties}
                highlightCardData={highlightData}
              />
              <AppTable
                onRowClick={handleRowClick}
                accentColor={highlightData.accent_color}
                columns={tableColumn}
                rows={
                  highlightData.view_from === "top"
                    ? highlightData.details
                    : highlightData.details.slice().reverse()
                }
              />
            </Box>
          ) : (
            <Box>
              <HighlightsCardSkeleton extraStyles={singleHighlightCardStyle} />
              <AppTableSkeleton />
            </Box>
          )}
        </>
      </Stack>
    </div>
  );
};

const singleHighlightCardStyle = css`
  border-radius: 1rem 1rem 0 0;
  padding: 1rem;

  height: auto !important;

  ${mq["sm"]} {
    height: 15rem !important;
  }

  ${mq["md"]} {
    height: auto !important;
  }

  ${mq["lg"]} {
    height: 18rem !important;
  }

  ${mq["xl"]} {
    height: 20.5rem !important;
  }
`;

const gwAndShareContainer = css`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const allSeasonAndShareContainer = css`
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;
