import liveRankIcon from "@/assets/landing-page/live-rank-icon.svg";
import { LANDING_PAGE_CONTENT } from "@/constants/landing-page.constants";
import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import quotesIcon from "@/assets/landing-page/quote-icon.svg";
import liveRankPhoneMockup from "@/assets/landing-page/live-rank-phone-mockup.png";
import Swoosh from "@/assets/landing-page/swoosh.svg";
import { mq } from "@/config/styles";
import { LandingPageHeader } from "../common/LandingPageHeader";

export const LiveStandings = () => {
  return (
    <Stack css={rootStyles}>
      <Stack css={liveRankSectionTitleContainerStyles}>
        <Typography variant="h2">
          {LANDING_PAGE_CONTENT.LIVE_RANK_SECTION_CONTENT.INTRO.SURTITLE}
        </Typography>
        <LandingPageHeader>
          {LANDING_PAGE_CONTENT.LIVE_RANK_SECTION_CONTENT.INTRO.TITLE}
          <img src={Swoosh} alt="swoosh" />
        </LandingPageHeader>
      </Stack>

      <Stack css={liveStandingsContainerStyles}>
        <Stack css={liveRankContentContainerStyles}>
          <Stack css={titleContainerStyles}>
            <img css={liveRankIconStyles} src={liveRankIcon} />
            <p css={titleStyles}>
              <img src={quotesIcon} css={quoteIconStyles} />
              {LANDING_PAGE_CONTENT.LIVE_RANK_SECTION_CONTENT.TITLE}
            </p>
          </Stack>
          <p css={contentStyles}>
            <img src={quotesIcon} css={quoteIconStyles} />
            {LANDING_PAGE_CONTENT.LIVE_RANK_SECTION_CONTENT.SUBTITLE}
          </p>
          <img
            style={{ rotate: "180deg" }}
            src={quotesIcon}
            css={quoteIconStyles}
          />
        </Stack>
        <Stack css={liveRankPhoneMockupStyles}>
          <img src={liveRankPhoneMockup} alt="live rank phone mockup" />
        </Stack>
      </Stack>
    </Stack>
  );
};

const rootStyles = css`
  & P {
    margin: 0;
  }
  gap: 2rem;
  padding: 1rem;
`;

const titleContainerStyles = css`
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;

  ${mq["md"]} {
    display: contents;
  }
`;

const liveStandingsContainerStyles = css`
  position: relative;
  gap: 2rem;
  display: flex;
  justify-content: space-between;
  ${mq["md"]} {
    flex-direction: row;
    align-items: center;
  }
`;

const liveRankSectionTitleContainerStyles = css`
  align-items: center;
  justify-content: center;
  padding-inline: 1rem;
  text-align: center;

  h2 {
    font-weight: 400;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
  }

  h1 {
    position: relative;
    isolation: isolate;
    img {
      position: absolute;
      top: 100%;
      left: 50%;
      translate: -50%;
      width: 85%;
      z-index: -1;
    }
  }
`;

const liveRankPhoneMockupStyles = css`
  position: absolute;
  top: 60%;
  right: 0;
  min-width: 50%;
  align-items: end;
  justify-content: end;
  max-width: 20rem;
  img {
    width: 80%;

    ${mq["md"]} {
      width: 100%;
    }
  }

  ${mq["md"]} {
    position: static;
    min-width: 50%;
  }
`;

const liveRankContentContainerStyles = css`
  flex: 1;
  align-items: start;
  gap: 1rem;
`;

const liveRankIconStyles = css`
  height: clamp(3rem, 5vw, 4rem);
  width: clamp(3rem, 5vw, 4rem);
`;

const titleStyles = css`
  font-size: clamp(1.8rem, 5vw, 2.375rem);
  font-weight: 700;
  position: relative;
  width: 55vw;

  & > img {
    display: none;
    position: absolute;
    right: 0;
    top: 0;

    translate: -50% -100%;
    ${mq["md"]} {
      width: auto;
      display: block;
      translate: 100% -100%;
    }
  }

  ${mq["md"]} {
    width: auto;
  }
`;

const contentStyles = css`
  position: relative;
  color: #98a2b3;
  font-size: clamp(1rem, 2vw, 1.125rem);
  font-weight: 400;
  letter-spacing: -0.045rem;
  max-width: 42vw;

  & > img {
    position: absolute;
    right: 0;
    top: 0;
    translate: 50% -50%;

    ${mq["md"]} {
      width: auto;
      display: none;
      translate: 100% -100%;
    }
  }
`;

const quoteIconStyles = css`
  display: block;
  aspect-ratio: 4.78081/3.4375;
  width: clamp(2rem, 7vw, 6rem) !important;
`;
