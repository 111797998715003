import { Helmet } from "react-helmet";
import { MINI_LEAGUE_PAGE } from "../../constants/mini-league.constants";
import { PageHeader, AppTabs } from "../../components";
import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { useGetUrl } from "../../utils/hooks/useGetUrl";
import { useNavigateState } from "../../utils/hooks/useNavigateState";

import leagueCaptainIcon from "../../assets/mini-league/league-captains-icon.svg";
import leagueTableIcon from "../../assets/mini-league/league-table-icon.png";
import mostHurtfulIcon from "../../assets/mini-league/most-hurtful-players-icon.png";
import { useGetMiniLeagueNewEntriesTable } from "../../utils/hooks/useGetMiniLeagueNewEntriesTable";
import { useEffect, useState } from "react";

/** Renders that main layout for the Mini league pages app tabs and an outlet */
export const MiniLeagueLayout = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigateState();

  const handleTabsChange = (_: any, clickedTab: string) => {
    navigate(clickedTab + search);
  };

  const {
    getMiniLeagueStandingsPath,
    getMiniLeagueCaptainsPath,
    getMiniLeagueMostHurtfulPath,
    getMiniLeagueNewEntriesPath,
  } = useGetUrl();

  const getInitialTabs = () => [
    {
      value: getMiniLeagueStandingsPath().split("?")[0],
      label: "League Tables",
      img: leagueTableIcon,
    },
    {
      value: getMiniLeagueCaptainsPath().split("?")[0],
      label: "League Captains",
      img: leagueCaptainIcon,
    },
    {
      value: getMiniLeagueMostHurtfulPath().split("?")[0],
      label: "Most Hurtful players",
      img: mostHurtfulIcon,
    },
    {
      value: getMiniLeagueNewEntriesPath().split("?")[0],
      label: "New Entries",
      img: leagueTableIcon,
    },
  ];

  const { newEntries } = useGetMiniLeagueNewEntriesTable({
    pageNumber: 1,
  });

  // Start without new Entries
  // pass anonyms to () => ... to avoid running on rerenders
  const [tabs, setTabs] = useState(() => getInitialTabs().slice(0, 3));

  // Add new Entries there is new entries
  useEffect(() => {
    if (newEntries?.newEntries.length) {
      // pass function name to avoid running on rerenders
      setTabs(getInitialTabs);
    } else {
      setTabs(() => getInitialTabs().slice(0, 3));
    }
  }, [newEntries, pathname]);

  return (
    <Stack css={rootStyles}>
      <Helmet>
        <title>{MINI_LEAGUE_PAGE.META.TITLE}</title>
      </Helmet>
      <PageHeader title={MINI_LEAGUE_PAGE.TITLE} />

      <AppTabs
        handleTabsChange={handleTabsChange}
        value={pathname}
        tabs={tabs}
      />

      <div>
        <Outlet />
      </div>
    </Stack>
  );
};

const rootStyles = css`
  gap: 1rem;
`;
