import { useQuery } from "react-query";
import { PATH_PARAMS } from "../../config/routes";
import { IFplManager } from "../types/types";
import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useParams, useSearchParams } from "react-router-dom";
import { useGlobalInfoContext } from "../../context/GlobalInfoContext";
import { useUserContext } from "../../context/UserContext";

export interface IMiniLeagueStanding extends IFplManager {
  captain_name: string;
  captain_pts: number | string;
  active_chip: string | null;
  players_left: {
    playersCount: string;
    message: "DNP" | "";
  };
}

export interface IMiniLeagueStandingResponse {
  standings: IMiniLeagueStanding[];
  has_next: boolean;
  eventStatus: "Updated" | string;
}

/** A react query custom hook to fetch and cache mini league standings table data requires a page number as a param */
export const useGetMiniLeagueData = ({
  isLiveRank,
  pageNumber,
  isActiveManagers,
}: {
  isLiveRank: boolean;
  pageNumber: number;
  isActiveManagers?: boolean;
}) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { liveGameWeek } = useGlobalInfoContext();
  const { user } = useUserContext();

  const queryKey = [
    "useGetMiniLeagueData",
    params[PATH_PARAMS.LEAGUE_ID],
    liveGameWeek,
    searchParams.get(PATH_PARAMS.SELECTED_PHASE),
    pageNumber,
    user?.teamId,
    isLiveRank,
    isActiveManagers,
  ];

  const queryFunction = async () => {
    if (
      params[PATH_PARAMS.LEAGUE_ID] &&
      params[PATH_PARAMS.LEAGUE_ID] !== "undefined" &&
      liveGameWeek &&
      searchParams.get(PATH_PARAMS.SELECTED_PHASE) &&
      user &&
      user.teamId
    ) {
      const miniLeagueStandingParams = {
        leagueId: params[PATH_PARAMS.LEAGUE_ID]!,
        gw: liveGameWeek!,
        phase: searchParams.get(PATH_PARAMS.SELECTED_PHASE)!,
        pageNumber,
        teamId: user?.teamId,
        isLiveRank,
        isActiveManagers,
      };

      const { data } = await axiosInstance.get<IMiniLeagueStandingResponse>(
        FPL_CHAMP_ENDPOINTS.MINI_LEAGUE(miniLeagueStandingParams)
      );

      let standings = data.standings;

      const fplManager =
        isLiveRank &&
        data.standings.find((m) => m.entry === Number(user?.teamId));

      if (fplManager) {
        standings = standings.filter(
          (m) => m.rank < 50 * pageNumber + 1 && m.rank > 50 * (pageNumber - 1)
        );

        if (
          !(
            fplManager.rank < 50 * pageNumber &&
            fplManager.rank > 50 * (pageNumber - 1)
          )
        ) {
          fplManager.rank > standings.at(-1)!.rank
            ? standings.push(fplManager)
            : standings.unshift(fplManager);
        }
      }

      return {
        eventStatus: data.eventStatus,
        has_next: !isLiveRank
          ? data.has_next
          : pageNumber * 50 < data.standings.length,
        standings,
      } as IMiniLeagueStandingResponse;
    }
  };

  const { isLoading, data, isFetching } = useQuery({
    queryKey: queryKey,
    queryFn: queryFunction,
  });

  return {
    miniLeagueStandings: data?.standings,
    hasNext: data?.has_next,
    eventStatus: data?.eventStatus,
    isLoading,
    isFetching,
  } as const;
};
