import { LANDING_PAGE_CONTENT } from "@/constants/landing-page.constants";
import { Stack, Typography } from "@mui/material";
import { css, SerializedStyles } from "@emotion/react";
import redditImage from "@/assets/landing-page/testimonials/reddit.svg";
import blurCircle from "@/assets/landing-page/testimonials-card-blur-circle.png";
import upvoteIcon from "@/assets/landing-page/testimonials/upvote.svg";
import { formatBoldText } from "@/utils/formateBoldText";

export const TestimonialCard = ({
  testimonial,
  extraStyles,
}: {
  extraStyles?: SerializedStyles;
  testimonial: (typeof LANDING_PAGE_CONTENT.TESTIMONIALS_SECTION_CONTENT.TESTIMONIALS)[0];
}) => {
  return (
    <Stack css={[rootStyles, extraStyles]}>
      <img src={blurCircle} alt="blur-circle" />

      <Stack css={headerContainerStyles}>
        <Stack css={userProfileContainerStyles}>
          <Stack css={profileImageContainerStyles}>
            <img
              css={profileImageStyles}
              src={`https://storage.googleapis.com/fpl-champ-testimonials/${testimonial.userName}.png`}
              alt={testimonial.userName}
            />
            <img css={platformIconStyles} src={redditImage} alt="reddit" />
          </Stack>
          <Typography css={userNameStyles}>u/{testimonial.userName}</Typography>
        </Stack>
        {testimonial.upVotes && (
          <Stack css={upvoteContainerStyles}>
            <img src={upvoteIcon} alt="upvote" />
            <span>{testimonial.upVotes}</span>
            <span>Upvotes</span>
          </Stack>
        )}
      </Stack>
      <Typography css={commentStyles}>
        {formatBoldText(testimonial.comment)}
      </Typography>
    </Stack>
  );
};

const rootStyles = css`
  border-radius: 1.5rem;
  height: clamp(7rem, 10vw, 11rem);
  padding: 1rem;
  gap: clamp(0.5rem, 1vw, 1rem);

  background: #2a2b3d;
  position: relative;
  overflow: hidden;
  border-radius: 1.5rem;

  & > img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
    filter: blur(1rem);
  }
`;

const headerContainerStyles = css`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const userProfileContainerStyles = css`
  flex-direction: row;
  gap: clamp(0.5rem, 1vw, 1rem);
  align-items: center;
`;

const upvoteContainerStyles = css`
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  & > span {
    font-size: clamp(0.75rem, 1.5vw, 1rem);
    font-weight: 600;
    line-height: 1;
  }

  img {
    width: clamp(0.75rem, 1.5vw, 1rem);
  }
`;

const profileImageContainerStyles = css`
  position: relative;
  width: fit-content;
`;

const profileImageStyles = css`
  display: block;
  width: clamp(1.3rem, 5vw, 3rem);
`;

const platformIconStyles = css`
  display: block;
  width: clamp(0.75rem, 1.5vw, 1.75rem);
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(25%, 25%);
`;

const userNameStyles = css`
  font-size: clamp(0.875rem, 1.5vw, 1.17544rem);
`;

const commentStyles = css`
  font-size: clamp(0.75rem, 1.5vw, 1rem);
`;
