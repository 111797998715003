import { axiosInstance } from "@/config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "@/config/fplChampEndpoints.constants";
import { PATH_PARAMS } from "@/config/routes";
import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";

export interface ISingleHighlightRes {
  view_from_top_desc: string;
  view_from_bottom_desc: string;
  details: {
    id: number;
    rank: number;
    player_name: string;
    total: number;
    entry: number;
    entry_name: string;
    gw_total_percentage_diff: string;
    gw_total_percentage_diff_number: number;
    gw_total_expected_points: number;
    gw_points_diff: number;
    gw_total_points: number;
    picks: {
      element: number;
      position: number;
      multiplier: number;
      is_captain: boolean;
      is_vice_captain: boolean;
      element_type: number;
      web_name: string;
      gw_expected_points: number;
      gw_points: number;
      gw_percentage_diff: string;
    }[];
  }[];
}

interface ISingleHighlightProps {
  season?: boolean;
  highlightId: string;
}

export const useGetSingleHighlight = (props: ISingleHighlightProps) => {
  const { season, highlightId } = props;
  const [searchParams] = useSearchParams();
  const params = useParams();

  const queryKey = [
    "useGetLuckiestHighlight",
    highlightId,
    params[PATH_PARAMS.LEAGUE_ID]!,
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK),
    season,
  ];

  const fetchSingleHighlight = async () => {
    if (queryKey.some((item) => item == null || item === "undefined")) {
      return;
    }
    const { data: luckiestHighlightData } =
      await axiosInstance.get<ISingleHighlightRes>(
        FPL_CHAMP_ENDPOINTS.SINGLE_HIGHLIGHT(
          highlightId,
          searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
          params[PATH_PARAMS.LEAGUE_ID]!,
          season
        )
      );
    return luckiestHighlightData;
  };

  const { refetch: getSingleHighlight, isFetching: isSingleLoading } = useQuery(
    {
      queryFn: fetchSingleHighlight,
      queryKey: queryKey,
      enabled: false,
    }
  );

  return { getSingleHighlight, isSingleLoading };
};
