import { createContext, useContext, useState } from "react";
import { ERROR_MESSAGES } from "../config/contextApi.constants";
import { SnackbarOrigin } from "@mui/material";

interface ISnackbarProvider {
  snackBarState: ISnackbar;
  initialSnackBarState: ISnackbar;
  setSnackBarState: React.Dispatch<React.SetStateAction<ISnackbar>>;
}

export interface ISnackbar {
  isOpen: boolean;
  message: string;
  duration: number; // Optional property for duration
  anchorOrigin: SnackbarOrigin;
  renderChildren?: React.FC;
  state?: "error" | "success" | "info" | "warning";
}

export const initialSnackBarState: ISnackbar = {
  isOpen: false,
  message: "",
  duration: 4000,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
};

const snackbarContext = createContext<ISnackbarProvider | undefined>(undefined);

/**
 * Custom hook to access the snackbar context.
 *
 * @returns {ISnackbarProvider} The context value containing the snackbar state and setter.
 * @throws {Error} Throws an error if the context is not provided.
 */
export const useSnackbarContext = () => {
  const snackbarState = useContext(snackbarContext);

  if (!snackbarState)
    throw new Error(ERROR_MESSAGES.NO_SNACKBAR_CONTEXT_PROVIDER);

  return snackbarState;
};

/**
 * Context provider for managing snackbar state.
 *
 * @component
 *
 * @description
 * Provides context for managing the snackbar state, including:
 * - `snackBarState`: Current state of the snackbar.
 * - `setSnackBarState`: Function to update the snackbar state.
 */
export const SnackbarContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [snackBarState, setSnackBarState] =
    useState<ISnackbar>(initialSnackBarState);

  return (
    <snackbarContext.Provider
      value={{
        snackBarState,
        setSnackBarState,
        initialSnackBarState,
      }}
    >
      {children}
    </snackbarContext.Provider>
  );
};
