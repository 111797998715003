import { css } from "@emotion/react";
import { Box, Stack, Typography } from "@mui/material";
import { mq } from "@/config/styles";
import { LANDING_PAGE_CONTENT } from "@/constants/landing-page.constants";
import Swoosh from "@/assets/landing-page/swoosh.svg";

// icons
import { ExtraFeaturesCard1 } from "./ExtraFeaturesCard1";
import { ExtraFeaturesCard2 } from "./ExtraFeaturesCard2";
import { ExtraFeaturesCard3 } from "./ExtraFeaturesCard3";
import { ExtraFeaturesCard4 } from "./ExtraFeaturesCard4";
import { ExtraFeaturesCard5 } from "./ExtraFeaturesCard5";
import { ExtraFeaturesCard6 } from "./ExtraFeaturesCard6";
import { LandingPageHeader } from "../common/LandingPageHeader";

/**
 * A component that renders the Extra Features section of the landing page.
 * This section displays six cards which provide additional information about
 * the features of the mini league.
 */
export const ExtraFeaturesSection = () => {
  return (
    <Stack>
      <div>
        <Stack css={highlightsSectionTitleContainerStyles}>
          <Typography variant="h2">
            {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.SURTITLE}
          </Typography>
          <LandingPageHeader>
            {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.TITLE}
            <img src={Swoosh} alt="swoosh" />
          </LandingPageHeader>
        </Stack>
      </div>

      <Box css={rootGridStyles}>
        <ExtraFeaturesCard1 />

        <ExtraFeaturesCard2 />

        <ExtraFeaturesCard3 />

        <ExtraFeaturesCard4 />

        <ExtraFeaturesCard5 />

        <ExtraFeaturesCard6 />
      </Box>
    </Stack>
  );
};

const rootGridStyles = css`
  --spacing: 1rem;
  ${mq["md"]} {
    --spacing: 1.5rem;
  }

  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  ${mq["md"]} {
    grid-template-columns: repeat(6, 1fr); /* 6 equal columns */
    grid-template-rows: auto auto auto clamp(25rem, 35vw, 30rem);
  }

  /* Grid items spanning logic */
  & > :nth-of-type(1) {
    ${mq["md"]} {
      grid-column: 1 / 4; /* spans 3 columns (1 to 4) */
      grid-row: 1 / 3; /* spans 2 rows (1 to 3) */
    }
  }

  & > :nth-of-type(2) {
    ${mq["md"]} {
      grid-column: 4 / 7; /* spans 3 columns (4 to 7) */
      grid-row: 1 / 3; /* spans 2 rows (1 to 3) */
    }
  }

  & > :nth-of-type(3) {
    ${mq["md"]} {
      grid-column: 1 / 7; /* spans all 6 columns */
      grid-row: 3 / 4; /* spans 1 row (3 to 4) */
    }
  }

  & > :nth-of-type(4) {
    ${mq["md"]} {
      grid-column: 1 / 3; /* spans 2 columns (1 to 3) */
      grid-row: 4 / 5; /* spans 1 row (4 to 5) */
    }
  }

  & > :nth-of-type(5) {
    ${mq["md"]} {
      grid-column: 3 / 5; /* spans 2 columns (3 to 5) */
      grid-row: 4 / 5; /* spans 1 row (4 to 5) */
    }
  }

  & > :nth-of-type(6) {
    ${mq["md"]} {
      grid-column: 5 / 7; /* spans 2 columns (5 to 7) */
      grid-row: 4 / 5; /* spans 1 row (4 to 5) */
    }
  }
`;

const highlightsSectionTitleContainerStyles = css`
  align-items: center;
  justify-content: center;
  padding-inline: 1rem;
  text-align: center;

  h2 {
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
  }

  h1 {
    margin-bottom: 2.5rem;
    position: relative;
    isolation: isolate;
    img {
      position: absolute;
      top: 100%;
      left: 50%;
      translate: -50%;
      width: 25%;
      z-index: -1;
    }
  }
`;
