import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { mq } from "../../config/styles";
import { secondaryColor } from "../../config/theme";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import { MINI_LEAGUE_PAGE } from "@/constants/mini-league.constants";

/** Snackbar that is shown when click on players left btn using in the mini league standing table */
export const PlayersLeftSnackbar = () => (
  <Stack css={activeManagersSnackbarStyles}>
    <SafetyDividerIcon />
    <Typography>{MINI_LEAGUE_PAGE.TABLE.PLAYERS_LEFT_SNACKBAR}</Typography>
  </Stack>
);

const activeManagersSnackbarStyles = css`
  background-color: ${secondaryColor};
  margin-inline: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  color: white;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 0.5rem;

  ${mq["lg"]} {
    align-items: center;
  }

  p {
    font-size: clamp(0.875rem, 5vw, 1rem);

    span {
      font-weight: 700;
    }
  }

  svg {
    font-size: 1.3rem;
  }
`;
