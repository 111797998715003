import { Pagination } from "swiper/modules";
import { Swiper as SwiperType } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import {
  TransferRow,
  TransfersCardSkeleton,
} from "../../transfers-pages/components";
import {
  IGetLeagueTransfersRes,
  useGetLeagueTransfers,
} from "../../../utils/hooks/useGetLeagueTransfers";
import { css } from "@emotion/react";
import { Box, Stack, Typography } from "@mui/material";
import {
  homeCardBgColor,
  cardBorderRadius,
  primaryGradient,
  mq,
} from "../../../config/styles";
import { TRANSFERS_PAGE_CONTENT } from "../../../constants/transfers.constants";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { Button, CTACard } from "../../../components";
import { Link, useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../../config/routes";
import { useState } from "react";
import { secondaryColor } from "../../../config/theme";
import { FPL_CHIPS } from "../../../constants/view-team-pages.constants";
import { getPointsColor } from "../../../utils/general.helpers";
import { TRANSFERS_WIDGET } from "../../../constants/home-page.constants";
import React from "react";

export const TransfersWidget = () => {
  const { leagueTransfers } = useGetLeagueTransfers(1, true);
  const [showCTACard, setShowCTACard] = useState(false);
  const { getTransfersPath } = useGetUrl();

  const handleSlideChange = (swiper: SwiperType) => {
    setShowCTACard(swiper.realIndex === 0 && swiper.swipeDirection === "next");
  };

  return (
    <div css={!!leagueTransfers && rootStyles}>
      <Swiper
        loop
        onSlideChangeTransitionEnd={handleSlideChange}
        modules={[Pagination]}
        spaceBetween={16}
        pagination={{
          clickable: true,
          el: ".bullet-container",
        }}
      >
        {!leagueTransfers
          ? Array.from({ length: 3 }, (_, index) => (
              <SwiperSlide key={index}>
                <TransfersCardSkeleton length={2} showBtn={false} />
              </SwiperSlide>
            ))
          : leagueTransfers.leagueTransfers.slice(0, 3).map((transfer) => (
              <SwiperSlide key={transfer.entry}>
                <TransfersWidgetCard transfers={transfer} />
              </SwiperSlide>
            ))}

        <Stack className="bullet-container" css={bulletsStyles} />
      </Swiper>
      <CTACard
        extraStyles={ctaExtraStyles}
        onCloseBtnClick={() => setShowCTACard(false)}
        show={showCTACard}
      >
        <Stack css={ctaCardTextStyles}>
          <Typography variant="h1">
            {TRANSFERS_WIDGET.CTA_CARD_CONTENT.TITLE}
          </Typography>
          <Typography variant="h2">
            {TRANSFERS_WIDGET.CTA_CARD_CONTENT.SUBTITLE}
          </Typography>
        </Stack>

        <Button button="gradient" to={getTransfersPath()}>
          {TRANSFERS_WIDGET.CTA_CARD_CONTENT.BTN}
        </Button>
      </CTACard>
    </div>
  );
};

const TransfersWidgetCard = ({
  transfers,
}: {
  transfers: IGetLeagueTransfersRes["leagueTransfers"][0];
}) => {
  const { getManagerTransfersPath } = useGetUrl();
  const [searchParams] = useSearchParams();
  const transferInfo = transfers.transfers[
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!
  ] as
    | IGetLeagueTransfersRes["leagueTransfers"][0]["transfers"][" "]
    | undefined;

  const { getTeamViewPath } = useGetUrl();

  const activeChip = transferInfo?.active_chip
    ? FPL_CHIPS[transferInfo?.active_chip]
    : null;
  const GW = searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK);
  const remainingTransfersCount =
    Object.keys(transfers.transfers).length - 2 > 0;
  const TitleComp = transfers?.entry ? Link : React.Fragment;
  return (
    <Stack css={transfersSummaryStyles}>
      <Stack css={topSectionContainerStyles}>
        <Stack css={transfersSummaryTitleStyles}>
          <TitleComp css={titleSection} to={getTeamViewPath(transfers?.entry!)}>
            <Typography>Transfer Analysis</Typography>
            <Typography variant="h1">
              {transfers.fpl_team_name}{" "}
              {activeChip && <img src={activeChip.img} alt={"chip"} />}
            </Typography>
            <Typography>GW {GW} transfers:</Typography>
          </TitleComp>

          {/** Points can be zero which is a falsy value */}
          {transferInfo && transferInfo.event_transfers_cost !== undefined && (
            <Typography css={netPointsStyles}>
              Net Points{" "}
              {`${transferInfo.net_points} (- ${transferInfo.event_transfers_cost})`}{" "}
              =
              <span
                style={{
                  color: getPointsColor(
                    transferInfo.net_points - transferInfo.event_transfers_cost
                  ),
                }}
              >
                {" "}
                {transferInfo.net_points -
                  transferInfo.event_transfers_cost}{" "}
                Pts
              </span>
            </Typography>
          )}
        </Stack>
      </Stack>

      <Box css={transfersSectionStyles}>
        {transferInfo && transferInfo.event_transfers.length !== 0 ? (
          <Stack css={transfersContainerStyles}>
            {transferInfo.event_transfers.map((item, i) => (
              <TransferRow
                key={i}
                element_in_info={item.element_in_info}
                element_out_info={item.element_out_info}
              />
            ))}
          </Stack>
        ) : (
          <p css={noTransfersStyles}>
            {TRANSFERS_PAGE_CONTENT.TRANSFERS_CARD.NO_TRANSFERS}
          </p>
        )}
      </Box>

      {remainingTransfersCount && (
        <Button
          button="gradient"
          css={btnStyles}
          to={getManagerTransfersPath(transfers.entry)}
        >
          {TRANSFERS_WIDGET.BTN(Object.keys(transfers.transfers).length - 2)}
        </Button>
      )}
    </Stack>
  );
};

const rootStyles = css`
  position: relative;
  height: 100%;
  ${mq["lg"]} {
    ${homeCardBgColor}
    border-radius: 1rem;
    padding-block: 0.5rem;
  }
`;

const transfersSummaryTitleStyles = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    max-width: 50%;
    & > h1 {
      max-width: 100% !important;
    }
  }
`;

const titleSection = css`
  h1 {
    font-size: clamp(1.125rem, 4vw, 1.675rem);
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    img {
      width: 1.3rem;
    }
  }

  p:first-of-type {
    font-size: clamp(1rem, 1.8vw, 1.125rem);
  }

  p:last-of-type {
    font-size: clamp(0.875rem, 1.5vw, 1rem);
  }
`;

const netPointsStyles = css`
  background: #222232;
  padding: 0.5rem 1rem;
  border-radius: 999rem;

  span {
    color: ${secondaryColor};
  }

  font-size: clamp(0.875rem, 1.5vw, 1rem);
  white-space: nowrap;
`;

const topSectionContainerStyles = css`
  padding: 0.5rem 1rem;
`;

const transfersSummaryStyles = css`
  ${homeCardBgColor}
  ${cardBorderRadius}
  overflow: hidden;
  position: relative;

  & > *:not(:last-child) {
    border-bottom: 1px solid #78787831;
  }
`;

const transfersSectionStyles = css`
  height: 210px;
`;

const transfersContainerStyles = css`
  max-height: 300px;
  overflow: auto;

  & > * {
    border-bottom: 1px solid #78787831;
  }
`;

const noTransfersStyles = css`
  font-weight: 700;
  text-align: center;
  font-size: clamp(1rem, 2vw, 1.5rem);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin: 0;
`;

const btnStyles = css`
  padding: 0;
  height: fit-content;
  width: 100%;
  border-radius: 0;

  /* position: absolute;
  bottom: 0;
  left: 0; */
`;

const bulletsStyles = css`
  flex-direction: row;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  gap: clamp(0.4rem, 0.8vw, 0.7rem);

  .swiper-pagination-bullet {
    cursor: pointer;
    width: auto;
    height: clamp(0.6rem, 1.5vw, 0.75rem);
    aspect-ratio: 1/1;
    background: #8e8e8e;

    &.swiper-pagination-bullet-active {
      background: ${secondaryColor} !important;
    }
  }
`;

const ctaCardTextStyles = css`
  h1 {
    ${primaryGradient}
    background-clip: text;
    color: transparent;
    font-weight: 700;
    font-size: clamp(1.2rem, 4vw, 1.5rem);
    text-align: center;
  }

  h2 {
    font-size: clamp(0.875rem, 2.5vw, 1.2rem);
    font-weight: 500;
    text-align: center;
  }
`;

const ctaExtraStyles = css`
  height: 91.5%;
  ${mq["lg"]} {
    height: 100%;
  }
`;
