import { useParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";
import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { IFplManager } from "../types/types";
import { queryClient } from "../../config/reactQuery.config";
import { useGlobalInfoContext } from "../../context/GlobalInfoContext";
import { useUserContext } from "../../context/UserContext";

export interface IMiniLeagueStandingsCaptainHistoryRes extends IFplManager {
  gameWeekCaptains: {
    [key: string]: {
      gameWeek: number | string;
      captain_name: string;
      captain_field_position: string;
      captain_team: number | string;
      gw_total_points: number | string;
    };
  };
}

/**
 * fetch mini league captains data if history is true fetch standing table history view
 * if history is false fetch captains table and captains cards
 */

export const useGetMiniLeagueStandingsTableHistory = ({
  pageNumber,
}: {
  pageNumber: number;
}) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { user } = useUserContext();

  const { liveGameWeek } = useGlobalInfoContext();

  const queryKey = [
    {
      leagueId: params[PATH_PARAMS.LEAGUE_ID]!,
      gw: liveGameWeek,
      phase: searchParams.get(PATH_PARAMS.SELECTED_PHASE)!,
      pageNumber,
      history: true,
      teamId: user?.teamId,
    },
  ];

  const queryFunction = async () => {
    if (
      params[PATH_PARAMS.LEAGUE_ID] &&
      liveGameWeek &&
      searchParams.get(PATH_PARAMS.SELECTED_PHASE) &&
      user?.teamId
    ) {
      const miniLeagueStandingParams = {
        leagueId: params[PATH_PARAMS.LEAGUE_ID]!,
        gw: liveGameWeek!,
        phase: searchParams.get(PATH_PARAMS.SELECTED_PHASE)!,
        pageNumber,
        history: true,
        teamId: user?.teamId,
        isLiveRank: false,
      };

      const { data } = await axiosInstance.get<
        IMiniLeagueStandingsCaptainHistoryRes[]
      >(FPL_CHAMP_ENDPOINTS.MINI_LEAGUE(miniLeagueStandingParams));

      return data;
    }
  };

  const fetchFunction = async () =>
    await queryClient.fetchQuery({
      queryKey: queryKey,
      queryFn: queryFunction,
    });

  return fetchFunction;
};
