import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { css } from "@emotion/react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactElement, SyntheticEvent, useState } from "react";
import { Button } from "./Button";
import { APP_TOOLTIP_CONSTANTS } from "../constants/global.constants";
import { AtLeastOne } from "../utils/types/types";

/** title or subtitle must be passed or both */
type titleAndSubTitleType = AtLeastOne<{
  title?: string;
  subTitle?: string;
}>;

interface IAppTooltip {
  color?: "dark" | "light" | string;
  children?: ReactElement;
  isSmall?: boolean;
}

/**
 * AppTooltip component must pass Title or SubTitle props or both
 * By default renders a Tooltip icon that opens a tooltip on click or hover
 * But can be overwritten by passing children
 */

export const AppTooltip = (props: IAppTooltip & titleAndSubTitleType) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (e: SyntheticEvent | Event) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleClose = (e?: SyntheticEvent | Event) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsOpen(false);
  };

  return (
    <Tooltip
      arrow
      onClick={handleOpen}
      open={isOpen}
      onClose={handleClose}
      enterTouchDelay={0} // disable wait on touch default 700ms
      leaveTouchDelay={0} // disable wait on close default 700ms
      onMouseEnter={handleOpen}
      slotProps={{
        arrow: {
          sx: {
            fontSize: "1rem",
            color: "white !important",
          },
        },
        tooltip: {
          sx: {
            background: "transparent",
            padding: 0,
            maxWidth: "none",
          },
        },
        popper: {
          sx: {
            padding: "0.7rem",
          },
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -19],
              },
            },
          ],
        },
      }}
      title={
        /**
         * can't pass subtitle of type string | undefined while title is also of
         * type string | undefined one must be passed
         * the ts can not resolve this from parent to child so we ignore the error
         * the child can be reused else where and the tile or the subTitle must be passed of
         * fixing the type on the child will cause an error
         */

        /* @ts-expect-error */
        <ToolTipContent
          handleBtnClick={handleClose}
          title={props.title}
          subTitle={props.subTitle}
        />
      }
      css={tooltipStyles}
    >
      {props.children ? (
        props.children
      ) : (
        <IconButton
          css={infoButtonStyles(
            props.color === "dark"
              ? "black"
              : !props.color
              ? "white"
              : props.color,
            Boolean(props.isSmall)
          )}
        >
          <InfoOutlinedIcon />
        </IconButton>
      )}
    </Tooltip>
  );
};

interface IToolTipContent {
  handleBtnClick(e: any | React.MouseEventHandler<HTMLButtonElement>): void;
}

/**
 * A reusable component for the content of the tooltip.
 * Render a title if passed or a subTitle if passed or both
 */
export const ToolTipContent = (
  props: IToolTipContent & titleAndSubTitleType
) => {
  return (
    <Stack css={containerStyles}>
      {props.title && (
        <Typography variant="h1" css={titleStyles}>
          {props.title}
        </Typography>
      )}
      {props.subTitle && (
        <Typography variant="h2" css={subTitleStyles}>
          {props.subTitle}
        </Typography>
      )}
      <Button button="text" css={buttonStyles} onClick={props.handleBtnClick}>
        {APP_TOOLTIP_CONSTANTS.BTN}
      </Button>
    </Stack>
  );
};

const containerStyles = css`
  gap: 0.8rem;
  width: 20rem;
  max-width: 90vw;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  color: black;
  box-shadow: 5px 0 25px #2222222e;
`;

const titleStyles = css`
  font-size: clamp(1rem, 5vw, 1.2rem);
  font-weight: 600;
`;

const subTitleStyles = css`
  font-size: clamp(0.875rem, 2vw, 1rem);
`;

const buttonStyles = css`
  display: block;
  margin-left: auto;
  width: fit-content;
  height: fit-content;
  padding: 0;
`;

const tooltipStyles = css`
  cursor: auto;
  user-select: none;
`;

const infoButtonStyles = (color: string, isSmall: boolean) => css`
  padding: 0.3rem !important; // important to override the style from the MUI tabs
  color: white;
  color: ${color};

  svg {
    font-size: clamp(1rem, 3vw, 1.5rem);
  }

  ${isSmall &&
  css`
    padding: 0.15rem !important; // important to override the style from the MUI tabs
    svg {
      font-size: clamp(0.9rem, 1.5vw, 1rem);
    }
  `}
`;
