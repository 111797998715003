import { useParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS, ROUTES } from "../../config/routes";
import { useGlobalInfoContext } from "../../context/GlobalInfoContext";
import { useEffect } from "react";
import { useIsSharedURL } from "./useIsSharedURL";

interface IUseGetUrl {
  defaultLeagueId?: string | number;
  selectedGameWeek?: string | number;
}

/**
 * Hook for generating URLs with dynamic parameters and query parameters based on current route state.
 * @param {IUseGetUrl} [props] - Optional object containing parameters to override default or URL-derived values.
 * @param {string|number} [props.defaultLeagueId] - Optional league ID to use instead of the one derived from the route.
 * @param {string|number} [props.selectedGameWeek] - Optional selected game week to set in query parameters.
 * @returns {Object} - An object containing methods to generate URLs based on current state.
 */

export const useGetUrl = (props?: IUseGetUrl) => {
  const { isShared } = useIsSharedURL();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { liveGameWeek } = useGlobalInfoContext();

  const defaultLeagueId =
    props && props.defaultLeagueId
      ? props.defaultLeagueId
      : params[PATH_PARAMS.LEAGUE_ID]!;

  useEffect(() => {
    if (!searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK) && liveGameWeek) {
      searchParams.set(PATH_PARAMS.SELECTED_GAME_WEEK, String(liveGameWeek));
      setSearchParams(searchParams);
    }
  }, [liveGameWeek]);

  if (props && props.selectedGameWeek) {
    searchParams.set(
      PATH_PARAMS.SELECTED_GAME_WEEK,
      String(props.selectedGameWeek)
    );
    setSearchParams(searchParams);
  }

  const getGameWeekAndPhaseParams = () => {
    const gameWeek = searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK);
    const phase = searchParams.get(PATH_PARAMS.SELECTED_PHASE) || "1";
    return `${PATH_PARAMS.SELECTED_GAME_WEEK}=${gameWeek}&${PATH_PARAMS.SELECTED_PHASE}=${phase}`;
  };

  const getGameWeek = () => {
    const gameWeek = searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK);
    return `${PATH_PARAMS.SELECTED_GAME_WEEK}=${gameWeek}`;
  };

  // Object containing methods to generate URLs with defaultLeagueId and current query parameters
  const getUrl = {
    getHomePath: (leagueId = defaultLeagueId) =>
      `/home/${leagueId}?${getGameWeekAndPhaseParams()}`,

    getHighlightsPath: (customLeagueId: string | number = defaultLeagueId) =>
      `/leagues/${customLeagueId}/highlights?${getGameWeek()}`,

    getHighlightsCategoryPath: (categoryId: string) =>
      `/leagues/${defaultLeagueId}/highlights/${categoryId}?${getGameWeek()}`,

    getSingleHighlightsPath: (categoryId: string, highlightId: string) =>
      `/leagues/${defaultLeagueId}/highlights/${categoryId}/${highlightId}?${getGameWeek()}`,

    getTeamViewPath: (teamId: string | number) =>
      `/leagues/${defaultLeagueId}/team/${teamId}?${getGameWeek()}`,

    getFixturesPath: () =>
      `/leagues/${defaultLeagueId}/fixtures?${getGameWeek()}`,

    getFixtureResultsOverviewPath: (fixtureId: string | number) =>
      `/leagues/${defaultLeagueId}/fixtures/${fixtureId}/overview?${getGameWeek()}`,

    getFixtureResultsOwnershipPath: (fixtureId: string | number) =>
      `/leagues/${defaultLeagueId}/fixtures/${fixtureId}/ownership?${getGameWeek()}`,

    getMiniLeagueStandingsPath: () =>
      `/leagues/${defaultLeagueId}?${getGameWeekAndPhaseParams()}`,

    getMiniLeagueCaptainsPath: () =>
      `/leagues/${defaultLeagueId}/captains?${getGameWeek()}`,

    getMiniLeagueMostHurtfulPath: () =>
      `/leagues/${defaultLeagueId}/most-hurtful?${getGameWeek()}`,

    getMiniLeagueNewEntriesPath: () =>
      `/leagues/${defaultLeagueId}/new-entries?${getGameWeek()}`,

    getTransfersPath: () =>
      `/leagues/${defaultLeagueId}/transfers?${getGameWeek()}`,

    getManagerTransfersPath: (teamId: string | number) =>
      `/leagues/${defaultLeagueId}/transfers/${teamId}?${getGameWeek()}`,
  };

  /**
   * If shared is true (come from a shared link) replace all links with Get stared link
   * except for the sharable links
   * If the user click on any link will go to GetStatedPage
   * This is important because of onClick event handlers that navigates the user
   */

  if (isShared) {
    Object.entries(getUrl).forEach(([key, value]) => {
      if (!value("", "").includes("/highlights")) {
        (getUrl as { [key: string]: (...params: any) => string })[key] = () =>
          ROUTES.TEAM_ID_PAGE;
      }
    });
  }

  return getUrl;
};
