import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { mq } from "../../config/styles";
import { secondaryColor } from "../../config/theme";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { formatBoldText } from "@/utils/formateBoldText";
import { MINI_LEAGUE_PAGE } from "@/constants/mini-league.constants";

/** Snackbar that is show n when user click active manager btn in the mini league standings table */
export const ActiveManagersSnackbar = () => (
  <Stack css={activeManagersSnackbarStyles}>
    <DirectionsRunIcon />
    <Typography>
      {formatBoldText(MINI_LEAGUE_PAGE.TABLE.ACTIVE_PLAYERS_SNACKBAR)}
    </Typography>
  </Stack>
);

const activeManagersSnackbarStyles = css`
  background-color: ${secondaryColor};
  margin-inline: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  color: white;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 0.5rem;

  ${mq["lg"]} {
    align-items: center;
  }

  p {
    font-size: clamp(0.875rem, 5vw, 1rem);
  }

  svg {
    font-size: 1.3rem;
  }
`;
