import { css, SerializedStyles } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { AppModal, Button } from "../../../../components";
import { useState } from "react";
import liveRankIcon from "../../../../assets/common/live_rank_icon.svg";
import { bgColor } from "../../../../config/theme";

/**
 * Renders a button that take children
 * The button open a Modal that explains the live rank feature
 */
export const LiveInfoModal = ({
  children,
  css,
}: {
  children: React.ReactNode;
  css?: SerializedStyles;
}) => {
  const [showLiveInfoModal, setShowLiveInfoModal] = useState(false);

  const handleCloseShowLiveStandingsModal = () => {
    setShowLiveInfoModal(false);
  };

  return (
    <>
      <button css={css} onClick={() => setShowLiveInfoModal(true)}>
        {children}
      </button>
      <AppModal
        open={showLiveInfoModal}
        onClose={handleCloseShowLiveStandingsModal}
      >
        <Stack css={modalContainerStyles}>
          <Stack css={modalTitleStyles}>
            <img src={liveRankIcon} alt="live-rank" />
            Introducing Live Rank{" "}
          </Stack>

          <Typography>
            FPLChamp now updates points and ranks LIVE! No more waiting—your
            mini-league standings, captain scores, and gameweek points refresh
            in real time, faster than the official FPL site. Stay ahead of the
            game and track your rank as the action unfolds!
          </Typography>

          <Button
            onClick={handleCloseShowLiveStandingsModal}
            button="gradient"
            css={modalButtonStyles}
          >
            Got it
          </Button>
        </Stack>
      </AppModal>
    </>
  );
};

const modalButtonStyles = css`
  color: ${bgColor};
  margin-left: auto;
`;

const modalContainerStyles = css`
  padding: 1rem 1rem 0rem 1rem;
  gap: 0.5rem;
`;

const modalTitleStyles = css`
  font-size: clamp(1.2rem, 5vw, 1.5rem);
  font-weight: 600;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;

  img {
    width: clamp(1.8rem, 5vw, 2.5rem);
  }
`;
