import { css } from "@emotion/react";
import { Box } from "@mui/material";
import noiseAndGradient from "@/assets/landing-page/gradient-and-noise.svg";

/**
 * A container component for the Extra Features section on the landing page.
 * This component wraps its children with a styled Box component that includes
 * a background image for visual enhancement. The container is styled with
 * specific CSS to ensure proper positioning and appearance within the layout.
 *
 * @param {Object} props - Component properties.
 * @param {React.ReactNode} props.children - The child elements to be rendered
 * within the container.
 */
export const ExtraFeaturesCardContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Box css={containerStyles}>
      <img src={noiseAndGradient} css={bgImageStyles} />

      {children}
    </Box>
  );
};

const containerStyles = css`
  position: relative;
  isolation: isolate;
  border-radius: 1.5rem;
  overflow: hidden;
`;

const bgImageStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;
