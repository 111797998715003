import { css } from "@emotion/react";
import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { primaryGradient } from "../../../config/styles";
import goal from "../../../assets/view-team/goal.svg";
import midField from "../../../assets/view-team/mid-field.svg";
import { bgColor } from "../../../config/theme";
import { Player, PlayerSkelton } from "../../../components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ITeamInfo } from "../../../utils/hooks/useGetTeamInfo";
import { FPL_CHIPS } from "../../../constants/view-team-pages.constants";
interface ITeamFormation {
  teamPlayers: ITeamInfo["picks"];
  activeChip: keyof typeof FPL_CHIPS | null;
  eplPlayerClick(eplPlayer: ITeamInfo["picks"][0]): void;
}

interface IPositionedPlayers {
  [key: string]: ITeamInfo["picks"];
}

/**
 * Render the team Formation field
 */

export const TeamFormation = (props: ITeamFormation) => {
  const [positionedPlayer, setPositionedPlayers] = useState<IPositionedPlayers>(
    {}
  );

  useEffect(() => {
    let positionedPlayerTemp: IPositionedPlayers = {};
    props.teamPlayers.slice(0, 11).map((teamPlayer) => {
      if (positionedPlayerTemp[teamPlayer.field_position]) {
        positionedPlayerTemp[teamPlayer.field_position].push(teamPlayer);
      } else {
        positionedPlayerTemp[teamPlayer.field_position] = [teamPlayer];
      }
    });
    positionedPlayerTemp["SUBS"] = props.teamPlayers.slice(11, 15);

    setPositionedPlayers(positionedPlayerTemp);
  }, [props.teamPlayers]);

  const assistantManager = props.teamPlayers[15];
  return (
    <Stack component={"ul"} css={rootStyles}>
      {assistantManager && props.activeChip === "manager" && (
        <Box
          css={assistantManagerContainerStyles}
          onClick={() => props.eplPlayerClick(assistantManager)}
        >
          <Player
            name={assistantManager.name}
            fieldPosition={assistantManager.field_position}
            points={
              assistantManager.gw_total_points * assistantManager.multiplier
            }
            playerShirt={assistantManager.shirt_link}
          >
            <span css={assistantManagerBadgeStyle}>
              <img src={FPL_CHIPS[props.activeChip].img} />
            </span>
          </Player>
        </Box>
      )}
      <img src={goal} alt="goal" />
      {Object.entries(positionedPlayer).map(
        ([position, playersType], playerRowIndex) => (
          <Stack component={"li"} key={position} css={playerRowStyle}>
            {playerRowIndex === 4 ? (
              <img src={midField} alt="mid Field" />
            ) : null}
            {playersType.map((player) => (
              <Box
                key={player.element}
                onClick={() => props.eplPlayerClick(player)}
                css={playerContainerStyles}
              >
                <Player
                  chanceOfPlaying={player.chance_of_playing_this_round}
                  name={player.name}
                  fieldPosition={player.field_position}
                  points={
                    player.gw_total_points *
                    (playerRowIndex === 4 ? 1 : player.multiplier)
                  }
                  playerShirt={
                    player.field_position === "GK"
                      ? player.gk_shirt_link
                      : player.shirt_link
                  }
                >
                  {(player.player_in || player.player_out) && (
                    <span css={subBadgeStyles(player.player_out)}>
                      {player.sub_index + 1} <KeyboardArrowRightIcon />
                    </span>
                  )}

                  <span css={badgesContainerStyle}>
                    {player.is_vice_captain && (
                      <span css={viceCaptainBadgeStyle}>V</span>
                    )}
                    {player.is_captain && (
                      <span css={captainBadgeStyle(props.activeChip === "3xc")}>
                        C
                      </span>
                    )}
                  </span>

                  {playerRowIndex === 4 ? (
                    <span css={fieldPositionBadgeStyle}>
                      {player.field_position}
                    </span>
                  ) : null}
                </Player>
              </Box>
            ))}
          </Stack>
        )
      )}
    </Stack>
  );
};

const rootStyles = css`
  position: relative;
  z-index: -100;
  border-radius: 1rem;
  overflow: hidden;
  background: repeating-linear-gradient(
    #22c55e 0px,
    #22c55e 5%,
    #38cb6e 5%,
    #38cb6e 10%
  );

  & > img {
    position: absolute;
    padding: inherit;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }
  box-shadow: 0 0 0 clamp(5px, 1vw, 10px) #38cb6e;
  border: clamp(3px, 0.4vw, 7px) solid white;
  border-bottom: none;
`;

const assistantManagerContainerStyles = css`
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

const assistantManagerBadgeStyle = css`
  position: absolute;
  top: 0rem;
  left: -0.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 999px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 1.5rem;
  }
`;

const badgeStyle = css`
  --width: clamp(1.2rem, 3vw, 2rem);
  display: block;
  width: var(--width);
  line-height: var(--width);
  text-align: center;
  aspect-ratio: 1/1;
  border-radius: 99999px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: clamp(0.75rem, 2vw, 1.12rem);
  font-weight: 600;
  color: ${bgColor};
`;

const captainBadgeStyle = (isTripleCapChipPlayed: boolean) => css`
  ${badgeStyle}
  background: linear-gradient(to bottom right,#FFF886, #F072B6);
  ${isTripleCapChipPlayed &&
  css`
    background: white;
    &::after {
      position: absolute;
      z-index: -1;
      top: -2px;
      left: -2px;
      content: "";
      display: block;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      ${primaryGradient}
      border-radius: 999px;
    }
  `}
`;

const viceCaptainBadgeStyle = css`
  ${badgeStyle}
  ${primaryGradient};
`;

const fieldPositionBadgeStyle = css`
  ${badgeStyle}
  position: absolute;
  right: auto;
  left: -0.25rem;
  top: -1rem;
  font-size: clamp(0.75rem, 3vw, 1rem);
  font-weight: 800;
`;

const badgesContainerStyle = css`
  display: block;
  position: absolute;
  right: -0.1rem;
  top: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.1rem;
`;

const subBadgeStyles = (isSubbedOut: boolean) => css`
  ${badgeStyle}
  position: absolute;
  right: auto;
  left: -0.25rem;
  top: 0.25rem;
  background-color: #0f830f;
  color: white;
  border-radius: 25%;
  width: fit-content;
  aspect-ratio: auto;
  padding-left: 0.5rem;

  svg {
    width: 1rem;
  }

  ${isSubbedOut &&
  css`
    background-color: #d23333;
    flex-direction: row-reverse;
    padding-right: 0.5rem;
    padding-left: 0;
    svg {
      rotate: 180deg;
    }
  `}
`;

const playerRowStyle = css`
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-block: clamp(0rem, 0.5vw, 1rem);

  &:last-of-type {
    position: relative;
    z-index: -100;
    padding-block: clamp(1.2rem, 2.5vw, 3rem);
    background-color: #f0f8ff7e;

    & > img:first-of-type {
      position: absolute;
      z-index: -1;
      top: 0;
      translate: 0 -50%;
      width: 100%;
    }
  }
`;

const playerContainerStyles = css`
  cursor: pointer;
`;

export const TeamFormationSkeleton = () => {
  let positionedPlayer = {
    GK: Array.from({ length: 1 }, () => ({ field_position: "GK" })),
    DEF: Array.from({ length: 4 }, () => ({ field_position: "DEF" })),
    MID: Array.from({ length: 4 }, () => ({ field_position: "MID" })),
    ATK: Array.from({ length: 2 }, () => ({ field_position: "ATK" })),
    SUBS: [
      { field_position: "GK" },
      { field_position: "DEF" },
      { field_position: "MID" },
      { field_position: "ATK" },
    ],
  };

  return (
    <Stack component={"ul"} css={[rootStyles, skeletonRootStyles]}>
      <img src={goal} alt="goal" />
      {Object.entries(positionedPlayer).map(
        ([position, playersType], playerRowIndex) => (
          <Stack component={"li"} key={position} css={playerRowStyle}>
            {playerRowIndex === 4 ? (
              <img src={midField} alt="mid Field" />
            ) : null}
            {playersType.map((_, playerIndex) => (
              <PlayerSkelton key={playerIndex} />
            ))}
          </Stack>
        )
      )}
    </Stack>
  );
};

const skeletonRootStyles = css`
  --bg-color: #111120;
  --bg-color-light: ${bgColor};
  background: repeating-linear-gradient(
    var(--bg-color) 0px,
    var(--bg-color) 5%,
    var(--bg-color-light) 5%,
    var(--bg-color-light) 10%
  );

  box-shadow: 0 0 0 clamp(5px, 1vw, 10px) #27273f;
  opacity: 0.5;
`;
